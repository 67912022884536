<!--
  This view is used to add / update promotions,
  which are used to automatically grant claims to users under certain conditions.
-->

<template>
  <div class="promotions-container form-container">
    <h2 class="page-title">Promotions</h2>
    <v-select
      v-model="selectedPromotion"
      outlined
      hide-details
      placeholder="Edit Existing Promotion"
      :items="promotions"
      item-text="name"
      item-value="id"
      return-object
      @change="selectPromotion"
    ></v-select>
    <TextInput v-model="newPromotion.name" label="Promotion Name" small />
    <TextInput v-model="newPromotion.id" label="Promotion ID" small @input="changeId" />
    <v-checkbox
      v-model="newPromotion.anonymousClaiming"
      label="Allow Anonymous Claiming?"
    />
    <p>Token Selection</p>
    <v-select
      v-model="newPromotion.collection"
      outlined
      hide-details
      placeholder="Promotion Collection"
      :items="tokenContracts || []"
      item-text="name"
      item-value="id"
      @change="changeCollection"
    ></v-select>

    <div
      v-for="(pType, i) in promoTypes"
      :key="`p-type-${i}`"
      class="probabilities"
    >
      <TextInput
        v-if="newPromotion.chances[pType.id]"
        v-model.number="newPromotion.chances[pType.id].chance"
        :label="pType.name"
        type="number"
        small
        @input="calculatePrimaryTotals"
      />
      <div class="probabilities__secondary">
        <div
          v-for="(sType, j) in pType.secondary"
          :key="`s-type-${j}`"
          class="input-wrapper"
        >
          <TextInput
            v-if="
              newPromotion.chances[pType.id] !== undefined &&
              newPromotion.chances[pType.id].secondary !== undefined &&
              newPromotion.chances[pType.id].secondary[sType.id] !== undefined
            "
            v-model.number="newPromotion.chances[pType.id].secondary[sType.id].chance"
            :label="sType.name"
            type="number"
            small
            @input="calculateSecondaryTotals(pType.id)"
          />
          <TextInput
            v-if="
              newPromotion.chances[pType.id] !== undefined &&
              newPromotion.chances[pType.id].secondary !== undefined &&
              newPromotion.chances[pType.id].secondary[sType.id] !== undefined
            "
            v-model.number="newPromotion.chances[pType.id].secondary[sType.id].cap"
            label="cap"
            type="number"
            small
          />
          <v-checkbox
            v-model="newPromotion.featuredType"
            :false-value="null"
            :true-value="`${pType.id}.${sType.id}`"
            label="Featured?"
          />
        </div>
        <p class="total">
          Total:
          <span :class="{ warning: sTotals[pType.id] !== 100 }">
            {{ sTotals[pType.id] }}
          </span>
        </p>
      </div>
    </div>
    <p class="total primary">
      Primary Totals:
      <span :class="{ warning: pTotal !== 100 }">
        {{ pTotal }}
      </span>
    </p>

    <h4>Requirements</h4>
    <v-checkbox
      v-model="newPromotion.useEmailWhitelist"
      label="Use Email Whitelist?"
    />

    <div v-if="newPromotion.useEmailWhitelist">
    <button
      class="standard in-row"
      @click="showingUploadEmails = true"
    >
      Upload Email Whitelist
    </button>

    <button
      class="standard"
      @click="loadWhitelist"
    >
      View Existing Whitelist
    </button>
    </div>

    <v-checkbox
      v-model="ownershipRequirement"
      label="Ownership Requirement?"
      @change="toggleOwnershipRequirement($event)"
    />
    <div v-if="ownershipRequirement && newPromotion.requiresOwnership">
      <v-select
        v-model="newPromotion.requiresOwnership.type"
        outlined
        hide-details
        placeholder="Requirement Type"
        :items="['all', 'one']"
      ></v-select>
      <h4>of</h4>
      <div
        v-for="(coll, i) in Object.keys(newPromotion.requiresOwnership.of)"
        :key="`requirement-${i}`"
        class="requirement-collection"
      >
        <span class="requirement-collection__id">{{ coll }}</span>
        <button @click="removeRequirementCollection(coll)" class="requirement-collection__remove">
          <v-icon>mdi-close</v-icon>
        </button>
        <div
          v-for="(type, j) in Object.keys(newPromotion.requiresOwnership.of[coll])"
          :key="`type-${i}-${j}`"
        >
          - {{ type }}
        </div>
      </div>
      <v-select
        v-model="newRequirementCollection"
        outlined
        hide-details
        placeholder="Requirement Collection"
        :items="tokenContracts || []"
        item-text="name"
        item-value="id"
      ></v-select>
      <v-select
        v-model="newRequirementType"
        outlined
        hide-details
        placeholder="Requirement Type"
        :items="requirementTypes"
        item-text="name"
        item-value="id"
      ></v-select>
      <button class="standard" @click="addRequirement">
        Add Requirement
      </button>
    </div>

    <button
      class="standard in-row"
      :disabled="!valid"
      @click="addPromotion"
    >
      {{ editingPromotion ? 'Save Edits' : 'Add New Promotion' }}
    </button>
    <button
      v-if="editingPromotion"
      class="standard"
      @click="deletePromotion"
    >
      Delete Promotion
    </button>
    <p v-if="editingPromotion" class="warning existing">Note: You are editing an existing promotion.</p>

    <UploadEmailWhitelistModal
      v-if="showingUploadEmails"
      :targetPromotion="newPromotion.id"
      @close="showingUploadEmails = false"
    />
    <EmailWhitelistModal
      v-if="showingWhitelist"
      :emails="existingEmails"
      @close="showingWhitelist = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UploadEmailWhitelistModal from '@/components/modals/UploadEmailWhitelistModal'
import EmailWhitelistModal from '@/components/modals/EmailWhitelistModal'
import { formatChances } from '@/common/utils'
import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore'
export default {
  name: 'ManagePromotions',

  async created () {
    await this.tokenContractsListener()
    await this.loadPromotions()
  },

  components: {
    UploadEmailWhitelistModal,
    EmailWhitelistModal
  },

  data () {
    return {
      newPromotion: {
        chances: {}
      },
      pTotal: 0,
      sTotals: {},
      selectedPromotion: {},
      editingPromotion: false,
      sTypes: [],
      ownershipRequirement: false,
      newRequirementCollection: null,
      newRequirementType: null,
      showingUploadEmails: false,
      showingWhitelist: false,
      existingEmails: []
    }
  },

  computed: {
    ...mapGetters([
      'tokenContracts',
      'marketData',
      'primaryTypes',
      'secondaryTypes',
      'promotions'
    ]),

    valid () {
      if (
        !this.newPromotion.id ||
        !this.newPromotion.name ||
        !this.newPromotion.collection
      ) {
        return false
      } else if (this.pTotal !== 100) {
        return false
      }
      return true
    },

    promoTypes () {
      if (!this.newPromotion.collection) { return [] }
      const types = []
      const primary = this.primaryTypes(this.newPromotion.collection).filter((type) => type.id !== 'all')
      primary.forEach((p) => {
        const copy = { ...p }
        copy.secondary = this.secondaryTypes(this.newPromotion.collection, p.id).filter((type) => type.id !== 'all')
        types.push(copy)
      })
      return types
    },

    requirementTypes () {
      if (!this.newRequirementCollection) { return [] }
      const types = []
      this.marketData[this.newRequirementCollection].types.forEach((type) => {
        types.push(type.id)
      })
      return types
    }
  },

  methods: {
    ...mapActions([
      'tokenContractsListener',
      'loadPromotions'
    ]),

    selectPromotion () {
      this.editingPromotion = true
      // Load details
      this.newPromotion = { ...this.selectedPromotion }
      // Then recalc all fields
      this.changeCollection()
      // And set existing
      this.newPromotion.chances = { ...this.newPromotion.chances, ...this.selectedPromotion.chances }

      if (this.newPromotion.requiresOwnership) {
        this.ownershipRequirement = true
      }
      this.calculatePrimaryTotals()
      // This is a bit hacky but it seems to work fine (essentially sets the ID to what it should be after the watch auto update)
      setTimeout(() => {
        this.newPromotion.id = this.selectedPromotion.id
      }, 1)
    },

    changeCollection () {
      this.pTotal = 0
      this.sTotals = {}
      this.newPromotion.chances = {}
      this.promoTypes.forEach((p) => {
        this.newPromotion.chances[p.id] = {
          secondary: {
          }
        }
        p.secondary.forEach((s) => {
          this.newPromotion.chances[p.id].secondary[s.id] = {}
        })
      })
    },

    toggleOwnershipRequirement (state) {
      if (state) {
        this.newPromotion.requiresOwnership = {
          of: {}
        }
      } else {
        this.newPromotion.requiresOwnership = null
      }
    },

    addRequirement () {
      if (!this.newPromotion.requiresOwnership.of[this.newRequirementCollection]) {
        this.newPromotion.requiresOwnership.of[this.newRequirementCollection] = {}
      }
      this.$set(this.newPromotion.requiresOwnership.of[this.newRequirementCollection], this.newRequirementType, true)
      this.newPromotion = { ...this.newPromotion }
    },

    removeRequirementCollection (collection) {
      delete this.newPromotion.requiresOwnership.of[collection]
      this.newPromotion = { ...this.newPromotion }
    },

    calculatePrimaryTotals () {
      this.pTotal = 0
      Object.keys(this.newPromotion.chances).forEach((p) => {
        const chance = this.newPromotion.chances[p].chance
        this.pTotal += chance || 0
      })
    },

    calculateSecondaryTotals (pTypeId) {
      const copy = { ...this.sTotals }
      copy[pTypeId] = 0
      Object.keys(this.newPromotion.chances[pTypeId].secondary).forEach((s) => {
        copy[pTypeId] += this.newPromotion.chances[pTypeId].secondary[s].chance || 0
      })
      this.sTotals = { ...copy }
    },

    changeId () {
      let match = false
      this.promotions.forEach((promotion) => {
        if (this.newPromotion.id === promotion.id) {
          match = true
        }
      })
      this.editingPromotion = match
    },

    async loadWhitelist () {
      if (!this.newPromotion.id) {
        this.addNotification('No promotion ID set.', 'error')
        return
      }
      this.showingWhitelist = true
      const res = await getDoc(doc(this.$fb.db, 'promotions', this.newPromotion.id, 'whitelist', 'emails'))
      if (res.data() && res.data().global) {
        this.existingEmails = res.data().global
      }
    },

    async addPromotion () {
      // Filter out unset types
      Object.keys(this.newPromotion.chances).forEach((p) => {
        if (!this.newPromotion.chances[p].chance || this.newPromotion.chances[p].chance === 0) {
          delete this.newPromotion.chances[p]
        }
      })

      // The formatChances subroutine is defined in utils because it's also used by the Firebase functions test suite
      this.newPromotion.chancesFormatted = formatChances(this.newPromotion.chances)

      console.log('After formatting:')
      console.log(this.newPromotion.chancesFormatted)

      try {
        await setDoc(doc(this.$fb.db, 'promotions', this.newPromotion.id), this.newPromotion)
        this.addNotification(`Updated promotion ${this.newPromotion.id}.`, 'success')
      } catch (err) {
        console.log(err)
        this.addNotification('Error adding new promotion.', 'error')
      }
    },

    async deletePromotion () {
      console.log('Deleting current promotion.')
      try {
        await deleteDoc(doc(this.$fb.db, 'promotions', this.newPromotion.id))
        this.addNotification(`Promotion ${this.newPromotion.id} deleted.`, 'success')
        this.loadPromotions()
      } catch (err) {
        console.log(err)
        this.addNotification('Error deleting promotion.', 'error')
      }
    }
  },

  watch: {
    'newPromotion.name' (name) {
      this.newPromotion.id = name.toLowerCase().replaceAll(' ', '-')
    },
    'newPromotion.id' () {
      this.newPromotion.id = this.newPromotion.id.replaceAll(' ', '-')
    }
  }
}
</script>

<style lang="scss" scoped>
.page-title {
  margin-bottom: $space-l;
}
.promotions-container {
  p {
    margin-bottom: $space-s;
  }
}

.probabilities {
  display: flex;
  gap: $space-m;
  margin-bottom: $space-m;

  .input-wrapper {
    display: flex;
    gap: $space-m;
  }

  .field-container {
    flex: 1
  }
  &__secondary {
    flex: 2;
  }
}

.total {
  font-size: $font-size-xs;

  &.primary {
    margin-bottom: $space-m;
  }
}

button.in-row {
  margin-right: $space-m;
}

.warning {
  color: red;
  &.existing {
    font-size: $font-size-xs;
  }
}

h4,
.v-select,
button.standard {
  margin-bottom: $space-m;
}

.requirement-collection {
  position: relative;
  border-bottom: solid 1px $blk-grey-2;
  padding-bottom: $space-s;
  margin-bottom: $space-m;

  &__id {
    color: $blk-grey-3;
  }

  &__remove {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
