<template>
  <Modal
    class="import-emails-modal"
    @close="closeModal"
    cancelLabel="Cancel"
    :onCancel="closeModal"
    confirmLabel="Upload"
    :onConfirm="uploadEmails"
    title="Email Whitelist"
    :subTitle="`Import Emails for ${targetPromotion}`"
  >
    <TextInput
      v-model="emailColumn"
      type="number"
      label="Email column"
    />
    <span>
      <div class="upload-wrapper">
        <label
          class="hitbox"
          for="csv-selection"
        >
          <input
            type="file"
            id="csv-selection"
            ref="csv-selection"
            @change="onFileChange"
          >
        </label>
        <button class="standard modal-upload">
          {{ csvFile && csvFile.name ? `${csvFile.name} (${Math.floor(csvFile.size * 0.001)} KB)` : 'Browse/Drop CSV File' }}
        </button>
        <p v-if="currentEmail" class="text">
          Uploaded {{ currentEmail }} emails
        </p>
      </div>
    </span>

    <template v-if="notAdded.length">
      <p>Not uploaded ({{ notAdded.length }}):</p>
      <div class="not-added">
        <p class="small" v-for="(item, index) in notAdded" :key="`not-added-${index}`">
          {{ item }}
        </p>
      </div>
    </template>
  </Modal>
</template>

<script>
import Papa from 'papaparse'
import { isEmail } from '@/common/utils'
import { doc, setDoc, arrayUnion } from 'firebase/firestore'

export default {
  name: 'UploadEmailWhitelistModal',
  props: {
    targetPromotion: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      targetCsv: {},
      csvFile: null,
      currentEmail: 0,
      emailColumn: 0,
      notAdded: []
    }
  },
  methods: {
    onFileChange (e) {
      console.log('New CSV uploaded')
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      console.log(`Uploading to ${this.targetPromotion}`)
      this.csvFile = files[0]
      console.log(this.csvFile)
    },
    closeModal () {
      this.$emit('close')
    },
    uploadEmails () {
      this.notAdded = []
      const emailColumn = this.emailColumn
      this.currentEmail = 0
      Papa.parse(this.csvFile, {
        step: async (row) => {
          const email = (row.data[emailColumn]).toLowerCase()
          console.log(email)
          if (email && isEmail(email)) {
            this.currentEmail++
            try {
              setDoc(
                doc(this.$fb.db, 'promotions', this.targetPromotion, 'whitelist', 'emails'),
                { global: arrayUnion(email) }, { merge: true }
              )
              console.log(this.currentEmail, row)
              console.log('Added email')
            } catch (err) {
              console.log('Error adding email:')
              console.log(err)
            }
          } else {
            console.log('NOT AN EMAIL')
            this.notAdded.push(email)
          }
        },
        complete: () => {
          console.log('Finished parsing.')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.text {
  font-family: $font-title;
  font-size: 18px;
  line-height: 23.4px;
  margin-top: 20px;
  text-transform: uppercase;
}

.central {
  .field-container {
    text-align: left;
  }
  .sales-title {
    margin-bottom: $space-ms;
  }
}

.upload-wrapper {
  position: relative;
  .hitbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  #csv-selection {
    opacity: 0;
  }
}

.modal-upload {
  @include caption;
  color: $blk-grey-2;
  background-color: $blk-grey-6;
  border: dashed 1px $blk-grey-2 !important;
  padding: $space-xs $space-ml;
  margin: 75px 10px;
  width: auto;
}

.not-added {
  background-color: $blk-grey-6;
  padding: $space-s;
  text-align: left;
  max-height: 100px;
  overflow-y: auto;
}
</style>
