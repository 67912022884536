<template>
  <Modal
    title="Existing Emails"
    hideActions
    @close="$emit('close')"
  >
    <div v-if="emails.length" class="email-list">
      <p v-for="(email, index) in emails" :key="`email-${index}`">{{ email }}</p>
    </div>
  </Modal>
</template>

<script>
export default {
  props: {
    emails: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
.email-list {
  text-align: left;
  max-height: 310px;
  overflow-y: auto;
}
</style>
